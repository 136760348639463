:root {
  --fColor: #64d8e6;
  --sColor: #9198e5;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  overflow-x: hidden;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture {
  max-width: 100%;
  display: block;
}


input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.App {
  text-align: center;
}
.navs{
  background-color: var(--fColor);
  color: black!important;
}
.home{
  width: 100vw;
  height: 43vh;
  
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(var(--fColor), var(--sColor));
  /* border-bottom-left-radius: 50% 35%; */
  border-bottom-right-radius: 50% 130px;
  flex-direction: column;
}
.curve{
  margin-top: 0;
  border-top-left-radius: 50% 100%;
  width: 50%;
  position: relative;
  height: 130px;
  background: white;
  z-index: 1;
}
.curve2 {
  position: absolute;
  background: var(--sColor);
  width: 50%;
  height: 130px;
}
.projects{
  margin: auto 0;
}
footer{
  margin-top: auto ;
  background-color: #9099e5;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 7vh;
  color: white;
  min-width: -webkit-fill-available;
}
.highlight::before {  
  transform: scaleX(0);
  transform-origin: bottom right;
}

.highlight:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.highlight::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  inset: 0 0 0 0;
  background: hsl(200 100% 80%);
  z-index: -1;
  transition: transform .3s ease;
}

.highlight {
  position: relative;
  /* font-size: 3rem; */
}
/* h1{
  font-size: 3rem!important;
} */
a{
  color: black!important;
  text-decoration: inherit!important;
}
.nav-link{
  text-align: center;
}
.logo{
  width: 50px;
}
.projectHolder{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.projectsImg{
  
  margin-bottom: 10rem;
}
.boxP{
  width: 45vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.textP {
  padding: 0px 25px;
  height: 32vh;
  justify-content: space-evenly;
}
.textP > p{
  color: black;
}
.button{
  width: 120px;
  height: 40px;
  background: linear-gradient(90deg, var(--fColor), var(--sColor));
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s ;
}
.button:hover{
  background: linear-gradient(180deg, var(--fColor), var(--sColor));
  /* color: pink!important; */
}

/* animation: animateCircle 5s linear infinite; */

.links{
  width: fit-content;
}


@media all and (max-width: 800px) {
  .boxP{
    width: 80vw;
  }
  .projectsImg {
    margin-bottom: 2rem;
  }
  .button{
    margin-bottom: 2rem;
  }
  /* h1, .highlight{
    font-size: 1.8rem!important;
  } */
}
@media all and (max-width: 500px) {
  .boxP{
    width: 370px;
  }
  footer{
 
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    
  }
  .links{
    margin-top: 5px;
  }
  .space{
    margin-bottom: 12px;
  }
}
@media all and (max-width: 340px) {
  .boxP{
    width: 250px;
  }
}
